import { Button } from "antd"
import PropTypes from "prop-types"

const CPButton = ({
  disabled,
  onClick,
  icon,
  type,
  size,
  shape,
  className = "",
  htmlType,
  loading,
  style,
  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      style={
        type === "plain" ? { ...style, border: "0px solid transparent" } : style
      }
      type={type}
      className={className}
      onClick={onClick}
      icon={icon}
      disabled={disabled}
      size={size}
      shape={shape}
      htmlType={htmlType}
      loading={loading}
    >
      {children}
    </Button>
  )
}

CPButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  type: PropTypes.oneOf([
    "grey",
    "primary",
    "secondary",
    "ghost",
    "dashed",
    "danger",
    "default",
    "plain",
    "info",
  ]),
  size: PropTypes.string,
  shape: PropTypes.string,
  className: PropTypes.string,
  htmlType: PropTypes.node,
  loading: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
}

CPButton.defaultProps = {
  children: "",
  disabled: false,
  onClick: () => {},
  icon: "",
  type: "default",
  size: "default",
  className: null,
  shape: null,
  htmlType: "button",
  loading: false,
  style: null,
}

export default CPButton
