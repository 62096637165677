import { configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import addPostReducer from "states/slices/addPostSlice"
import areaOfStudiesReducer from "states/slices/areaOfStudiesSlice"
import communityCategory from "states/slices/communityCategorySlice"
import communityReducer from "states/slices/communitySlice"
import companyAOEReducer from "states/slices/companyAOESlice"
import companyIndustriesReducer from "states/slices/companyIndustriesSlice"
import companySizesReducer from "states/slices/companySizesSlice"
import companyReducer from "states/slices/companySlice"
import companyTypeReducer from "states/slices/companyTypeSlice"
import countryReducer from "states/slices/countrySlice"
import degreeReducer from "states/slices/degreeSlice"
import feedReducer from "states/slices/feedSlice"
import individualAOEReducer from "states/slices/individualAOESlice"
import languageReducer from "states/slices/languageSlice"
import materialsReducer from "states/slices/materialsSlice"
import messageReducer from "states/slices/messageSlice"
import policyLists from "states/slices/policyListsSlice"
import productCategoriesReducer from "states/slices/productCategoriesSlice"
import productPropertiesReducer from "states/slices/productPropertiesSlice"
import profileReducer from "states/slices/profileReducer"
import projectFunctionsReducer from "states/slices/projectFunctionsSlice"
import projectScalesReducer from "states/slices/projectScalesSlice"
import projectStatusReducer from "states/slices/projectStatusSlice"
import projectTypesReducer from "states/slices/projectTypesSlice"
import propCompanyReducer from "states/slices/propCompanySlice"
import serviceAreasReducer from "states/slices/serviceAreasSlice"
import serviceCategoriesReducer from "states/slices/serviceCategoriesSlice"
import skillsReducer from "states/slices/skillsSlice"
import socialNetworksReducer from "states/slices/socialNetworksSlice"

const reducers = {
  communityCategory,
  propCompany: propCompanyReducer,
  addPost: addPostReducer,
  policies: policyLists,
  company: companyReducer,
  community: communityReducer,
  feed: feedReducer,
  skills: skillsReducer,
  degrees: degreeReducer,
  userInfo: profileReducer,
  country: countryReducer,
  message: messageReducer,
  languages: languageReducer,
  companyAOE: companyAOEReducer,
  companyType: companyTypeReducer,
  projectTypes: projectTypesReducer,
  companySizes: companySizesReducer,
  individualAOE: individualAOEReducer,
  areaOfStudies: areaOfStudiesReducer,
  projectStatus: projectStatusReducer,
  projectScales: projectScalesReducer,
  socialNetworks: socialNetworksReducer,
  projectFunctions: projectFunctionsReducer,
  serviceCategories: serviceCategoriesReducer,
  serviceMaterials: materialsReducer,
  productProperties: productPropertiesReducer,
  companyIndustries: companyIndustriesReducer,
  productCategories: productCategoriesReducer,
  serviceAreas: serviceAreasReducer,
}

export const makeStore = (preloadedState = {}) =>
  configureStore({
    reducer: reducers,
    devTools: true,
    preloadedState,
  })

export const wrapper = createWrapper(makeStore, { debug: false })
