import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "feed",
  initialState: {
    editMode: null,
  },
  reducers: {
    setData: (state, action) => {
      state.editMode = action.payload
    },
  },
})

export const { setData } = slice.actions

export const feedState = state => state?.feed

export default slice.reducer
