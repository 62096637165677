import { Input } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"

function CPInput({ labelconfig = {}, className = "", ...props }) {
  const classes = `w-full py-2 rounded-lg ${className}`

  if (props?.type === "password") {
    return <Input.Password {...props} {...labelconfig} className={classes} />
  }
  return <Input {...props} {...labelconfig} className={classes} />
}

export default CPInputWithLabelHOC(CPInput)
