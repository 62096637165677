import { Card, Divider } from "antd"
import ProfileMenuCompanyList from "components/Header/ProfileMenuCompanyList"
import ProfileMenuUserInfo from "components/Header/ProfileMenuUserInfo"
import { logoutOperation } from "services/authService"

export default function ProfileMenu({
  drawer = false,
  className = "",
  setAddCommunity,
  setAddCompany,
  onClose,
}) {
  const handleAddCompany = () => {
    if (setAddCompany) {
      setAddCompany(true)
    }
    if (onClose) {
      onClose()
    }
  }

  const handleAddCommunity = () => {
    if (setAddCommunity) {
      setAddCommunity(true)
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Card
      style={{
        maxWidth: drawer ? "" : 300,
        minWidth: drawer ? "" : 250,
        width: "100%",
      }}
      styles={{ body: { padding: "0 10px" } }}
      className={`overflow-hidden p-0 border-0 ${className}`}
    >
      {!drawer && <ProfileMenuUserInfo />}

      {/*<ProfileMenuAccount />*/}

      <ProfileMenuCompanyList overflow={!drawer} onClose={onClose} />

      <Divider className="border-gray-300 my-1" />

      <div
        className={`${
          drawer ? "mt-1" : ""
        } text-primary py-1 flex items-center hover:text-black border-0 cursor-pointer`}
        onClick={handleAddCompany}
      >
        <span className="f-20 mr-2">+</span>
        <span>Add Company Profile</span>
      </div>
      <div
        className="text-primary py-1 flex items-center hover:text-black border-0 cursor-pointer"
        onClick={handleAddCommunity}
      >
        <span className="f-20 mr-2">+</span>
        <span>Add Community</span>
      </div>

      <Divider className="border-gray-300 my-1" />

      <div
        className={`${
          drawer ? "mt-2" : ""
        } text-gray-500 py-2 flex items-center hover:text-black border-0 cursor-pointer`}
        onClick={logoutOperation}
      >
        <span className="icon-ic_power_settings_new_24px mr-2" />
        <span>Sign Out</span>
      </div>
    </Card>
  )
}
