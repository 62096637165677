import { Input } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"
import styled from "styled-components"

const { TextArea } = Input

const CustomTextArea = styled(TextArea)`
  padding: 7px 9px;
  border-radius: 0.375rem;
`

function CPTextArea(props) {
  return (
    <>
      <CustomTextArea
        {...props}
        rows={props?.rows || 4}
        maxLength={props?.limit || null}
      />

      {props?.limit && (
        <div className="text-right text-gray-500">
          {props?.limit - (props?.value?.length || 0)} characters left
        </div>
      )}
    </>
  )
}

export default CPInputWithLabelHOC(CPTextArea)
