import { Button, Divider, Radio } from "antd"
import { useState } from "react"

export default function AddCommunityPrivacyLevel({
  initialData,
  onSubmit,
  onBack,
}) {
  const visibilities = [
    {
      icon: "icon-ic_public_24px",
      name: "Public Community",
      value: "public",
      description: "Anyone can see community activities and members",
    },
    {
      icon: "icon-ic_lock_24px",
      name: "Private Community",
      value: "private",
      description: "Only members can see community activities and members",
    },
    {
      icon: "icon-ic_visibility_off_24px",
      name: "Secret Community",
      value: "secret",
      description: "Only invited members can find this community",
    },
  ]
  const [selected, setSelected] = useState(
    initialData?.privacy || visibilities[0].value
  )

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit({ privacy: selected })
      }}
      className="pt-4"
    >
      <div style={{ minHeight: 260 }}>
        {visibilities?.map((item, index) => (
          <div
            key={index}
            className={`flex py-3 px-5 cursor-pointer hover-gray transition mb-1 ${
              item?.value === selected ? "selected-primary" : ""
            }`}
            onClick={() => setSelected(item.value)}
          >
            <span
              style={{ minWidth: "26px" }}
              className={`${item?.icon} text-gray-600 mt-1 text-center f-20`}
            />
            <div className="ml-4 flex flex-col ant-text-grey">
              <strong className="f-16 text-gray-800">{item?.name}</strong>
              <span className="f-14">{item?.description}</span>
            </div>

            <div className="flex-grow" />
            <Radio
              className="m-0"
              checked={item?.value === selected}
              name="PostPrivacy"
            />
          </div>
        ))}
      </div>

      <Divider className="border-gray-300 border-t-2 mb-0 mt-4" />

      <div className="flex p-4 justify-end">
        <Button type="light" className="px-8" onClick={onBack}>
          Back
        </Button>
        <Button type="primary" className="px-8" htmlType="submit">
          Next
        </Button>
      </div>
    </form>
  )
}
