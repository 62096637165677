import { createSlice } from "@reduxjs/toolkit"
import apiService from "services/apiService"

const slice = createSlice({
  name: "country",
  initialState: {
    loading: false,
    country: null,
    city: null,
    error: false,
    errorMessage: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setData: (state, action) => {
      state.country = action.payload
    },
    setError: (state, action) => {
      state.errorMessage = action.payload
    },
  },
})

const { setData, setLoading, setError } = slice.actions

export const countryReduxState = state => state.country

export const getCountriesList = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const res = await apiService.get("/api/list/get-country-list")
    const dataList = res?.data?.data

    if (res.status === 200 && dataList?.length) {
      dispatch(setData(dataList))
    }
  } catch (e) {
    dispatch(setError(e?.response?.data))
  }

  dispatch(setLoading(false))
}

export const getCityList = () => async dispatch => {
  const res = await apiService.get("/api/list/get-country-list")
  const dataList = res?.data?.data

  if (dataList?.length) {
    dispatch(setData(dataList))
  }
}

export default slice.reducer
