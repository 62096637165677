import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "message",
  initialState: {
    isListExpand: false,
    isNewChatOpened: false,
    openedChatDetails: null,
  },
  reducers: {
    setListExpand: (state, action) => {
      state.isListExpand = action.payload
    },
    setNewChatOpened: (state, action) => {
      state.isNewChatOpened = action.payload
    },
    setOpenedChatDetails: (state, action) => {
      state.openedChatDetails = action.payload
    },
  },
})

export const { setListExpand, setNewChatOpened, setOpenedChatDetails } =
  slice.actions

export const messageState = state => state?.message

export default slice.reducer
