import { Button, Divider, Radio, Spin } from "antd"
import { AppContext } from "components/AppContext"
import { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPolicyList, policiesState } from "states/slices/policyListsSlice"

export default function AddCommunityPolicy({
  loading,
  onBack,
  onSubmit,
  initialData,
  editMode,
}) {
  const dispatch = useDispatch()
  const { details = null } = useContext(AppContext) || {}
  const { loading: dataLoading, communityData } = useSelector(policiesState)

  const policies = [
    {
      id: "add_member",
      title: "Who can invite new members?",
      children: [
        { title: "Admin/s", value: "super_admin" },
        { title: "All members", value: "member" },
      ],
    },
    {
      id: "create_post",
      title: "Who can post content?",
      children: [
        { title: "Admin/s", value: "super_admin" },
        { title: "All Members", value: "member" },
      ],
    },
    {
      id: "create_comment",
      title: "Who can comment on a post?",
      children: [
        { title: "Admin/s", value: "super_admin" },
        { title: "All Members", value: "member" },
      ],
    },
  ]
  const [selected, setSelected] = useState({
    add_member: "super_admin",
    create_post: "super_admin",
    create_comment: "super_admin",
  })

  useEffect(() => {
    if (!editMode || initialData) {
      return
    }
    dispatch(getPolicyList({ id: details.id }))
  }, [editMode])

  /**
   * in adding new community
   */
  useEffect(() => {
    if (!initialData) {
      return
    }

    const newData = { ...selected }
    Object.keys(initialData).forEach(key => {
      const value = newData[key]
      if (!value) {
        return
      }

      if (initialData[key]?.includes("member")) {
        newData[key] = "member"
      } else {
        newData[key] = "super_admin"
      }
    })

    setSelected(newData)
  }, [initialData])

  /**
   * in settings
   */
  useLayoutEffect(() => {
    if (!communityData || initialData) {
      return
    }

    const prevData = {}
    communityData?.forEach(item => {
      let key = prevData[item.type]
      if (!key) {
        key = []
      }
      if (!key.includes(item.scope)) {
        key.push(item.scope)
      }
      prevData[item.type] = key
    })

    const newData = {}
    Object.keys(prevData).forEach(key => {
      const value = prevData[key]
      if (value?.includes("member")) {
        newData[key] = "member"
      } else {
        newData[key] = "super_admin"
      }
    })

    setSelected(newData)
  }, [communityData])

  const onChange = (event, item) => {
    const value = event.target.value
    setSelected(prev => ({ ...prev, [item.id]: value }))
  }

  const generateData = event => {
    event?.preventDefault()

    const newData = {}
    Object.keys(selected).forEach(key => {
      const value = selected[key]
      if (value === "member") {
        newData[key] = ["super_admin", "member"]
      } else {
        newData[key] = [value]
      }
    })

    return newData
  }

  return (
    <form onSubmit={event => onSubmit(generateData(event))}>
      <div className="px-5" style={{ minHeight: 280 }}>
        {dataLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "25%",
            }}
          >
            <Spin spinning={true} />
          </div>
        )}
        {!dataLoading &&
          policies.map((item, index) => (
            <div key={index} className="my-4">
              <h3 className="f-13 mb-1 text-gray-600">{item?.title}</h3>

              <Radio.Group
                onChange={event => onChange(event, item)}
                value={selected[item?.id]}
              >
                {item?.children?.map((child, index) => (
                  <Radio key={index} value={child?.value}>
                    {child.title}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ))}
      </div>

      <Divider className="border-gray-300 border-t-1 m-0" />

      <div className="flex p-4 justify-end">
        <Button
          type="light"
          disabled={loading || dataLoading}
          className="px-8 my-0"
          htmlType="button"
          onClick={event => onBack(generateData(event))}
        >
          Back
        </Button>
        <Button
          type="primary"
          loading={loading}
          disabled={loading || dataLoading}
          className="px-8"
          htmlType="submit"
        >
          Done
        </Button>
      </div>
    </form>
  )
}
