import { Avatar, Button, Popconfirm } from "antd"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import moment from "moment"
import { useState } from "react"
import apiService from "services/apiService"

export default function RequestCommunityItem({ item, onFetch }) {
  const { checkSubscription } = useSubscriptionContext()
  const [loading, setLoading] = useState(false)

  const onChangeStatus = async type => {
    try {
      setLoading(true)

      await apiService.put(`/api/network/company/request/${item.id}`, {
        status: type,
      })
      if (onFetch) {
        onFetch()
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  return (
    <div className="mb-2 flex">
      <Avatar
        size={32}
        style={{ minWidth: 32 }}
        src={item.profile_photo || "/assets/onBoarding/profile_avatar.jpg"}
        alt={item.title || ""}
        className="bg-gray-100"
      />
      <div className="flex flex-col ml-3 flex-grow">
        <span className="f-12">
          {item.first_name} {item.last_name} wants you to be as admin member of{" "}
          {item?.company_name}
        </span>
        <span className="f-10 text-gray-500">
          {moment(item?.created_at).fromNow()}
        </span>

        <div className="flex mt-2 w-full">
          <Popconfirm
            okText="Yes"
            disabled={loading}
            cancelText="No"
            title="Are you sure you want to accept this request?"
            onConfirm={() => onChangeStatus("accepted")}
          >
            <Button type="primary" className="px-5">
              Accept
            </Button>
          </Popconfirm>
          <Popconfirm
            okText="Yes"
            disabled={loading}
            cancelText="No"
            title="Are you sure you want to reject this request?"
            onConfirm={() => onChangeStatus("rejected")}
          >
            <Button type="light" className="px-5">
              Reject
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  )
}
