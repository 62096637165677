import { Col, Row } from "antd"
import s from "components/Footer/footer.module.scss"
import Link from "next/link"

function handleHover(showOnHover) {
  if (showOnHover) {
    document.body.style.transform = "translateY(-150px)"
  }
}

function handleHoverEnd(showOnHover) {
  if (showOnHover) {
    document.body.style.transform = "translateY(0)"
  }
}

function Index({ showOnHover = false }) {
  return (
    <footer
      className={s.footer}
      onMouseEnter={() => handleHover(showOnHover)}
      onMouseLeave={() => handleHoverEnd(showOnHover)}
      onTouchStart={() => handleHover(showOnHover)}
      onTouchEnd={() => handleHoverEnd(showOnHover)}
      onClick={() => handleHover(showOnHover)}
      onBlur={() => handleHoverEnd(showOnHover)}
    >
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 20, offset: 2 }}
          xl={{ span: 18, offset: 3 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <Row gutter={[32, 4]}>
            <Col xs={24} lg={8} md={11}>
              <img
                src="/assets/logo_white.png"
                alt="logo"
                className="h-6 mb-3"
              />
              <p
                className="m-0"
                style={{ textAlign: "left", fontSize: "12px" }}
              >
                Birdflocks is a Smart Hub that empowers Architecture,
                Engineering, and Construction (AEC) professionals to connect,
                collaborate, and overcome industry challenges efficiently.
              </p>
            </Col>
            <Col
              xs={24}
              lg={{ offset: 0, span: 4 }}
              md={{ offset: 1, span: 6 }}
              sm={12}
            >
              <div className={`${s.title} lg:mt-0 mt-6`}>Company</div>
              <ul className={s.menu}>
                <li>
                  <Link passHref prefetch={false} href="/about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link passHref prefetch={false} href="/conditions">
                    Conditions
                  </Link>
                </li>
                <li>
                  <Link passHref prefetch={false} href="/privacy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link passHref prefetch={false} href="/career">
                    Career
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={24} lg={5} md={6} sm={12}>
              <div className={`${s.title} lg:mt-0 mt-6`}>Contact Us</div>
              <ul className={s.menu}>
                <li>
                  <a
                    href="mailto:hello@birdflocks.com?subject=Support"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hello@birdflocks.com
                  </a>
                </li>
                <li>Fjordveien 1 1363, HØVIK, VIKEN Norway</li>
              </ul>
            </Col>
          </Row>
          <div className={`${s.copyRight} lg:mt-0 mt-6`}>
            Copyright ©{new Date().getFullYear()}, All Rights Reserved by
            Birdflocks.
          </div>
        </Col>
      </Row>
    </footer>
  )
}

export default Index
