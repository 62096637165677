import { createSlice } from "@reduxjs/toolkit"
import apiService from "services/apiService"

const slice = createSlice({
  name: "policies",
  initialState: {
    loading: false,
    data: null,
    companyData: null,
    communityData: null,
    error: false,
    errorMessage: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCompanyData: (state, action) => {
      state.companyData = action.payload
    },
    setCommunityData: (state, action) => {
      state.communityData = action.payload
    },
    setError: (state, action) => {
      state.errorMessage = action.payload
    },
  },
})

const { setCompanyData, setCommunityData, setLoading, setError } = slice.actions

export const policiesState = state => state.policies
export const getPolicyList =
  ({ id = "" } = {}) =>
  async dispatch => {
    try {
      dispatch(setLoading(true))

      const url = id
        ? `/api/community/${id}/policies/`
        : "/api/network/company/admin/policies/"
      const res = await apiService.get(url)
      const dataList = res?.data?.data

      if (res.status === 200 && dataList?.length) {
        if (id) {
          dispatch(setCommunityData(dataList))
        } else {
          dispatch(setCompanyData(dataList))
        }
      }
    } catch (e) {
      dispatch(setError(e?.response?.data))
    }

    dispatch(setLoading(false))
  }

export default slice.reducer
