import { Avatar } from "antd"
import CPButton from "components/CP/CPButton"
import Link from "next/link"
import { useSelector } from "react-redux"
import { profileState } from "states/slices/profileReducer"
import { truncate } from "utils/global"

export default function ProfileMenuUserInfo() {
  const { userInfo } = useSelector(profileState)

  return (
    <>
      <div className="flex items-center pt-3 pb-1">
        <Avatar
          size={60}
          alt={userInfo?.first_name}
          src={userInfo?.icon || "/assets/onBoarding/profile_avatar.jpg"}
          className="border border-gray-300 shrink-0"
        />

        <div className="ms-4">
          <div className="f-17 text-gray-800 font-medium mb-0 truncate">
            {truncate(`${userInfo?.first_name} ${userInfo?.last_name}`, {
              length: 30,
            })}
          </div>
          {userInfo?.headline && (
            <div className="f-13 text-gray-500 f-12 truncate">
              {truncate(userInfo?.headline, { length: 30 })}
            </div>
          )}
        </div>
      </div>

      <Link passHref prefetch={false} href={`/profile/${userInfo?.username}`}>
        <CPButton
          type="primary"
          block
          className="mt-2 mb-4 py-2 text-black border-black"
        >
          View Profile
        </CPButton>
      </Link>
    </>
  )
}
