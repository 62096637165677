import { notification } from "antd"
import { logoutOperation } from "services/authService"

const successStatusCodes = new Set([200, 201])

export const notifyUser = ({
  title = "",
  description = "",
  type = "error",
}) => {
  notification[type]({
    message: title,
    placement: "topRight",
    description,
  })
}

export const notifyToast = error => {
  if (error?.response?.data?.errors?.messages === "Unauthenticated") {
    return logoutOperation()
  }

  const { status, data } = error?.response || {}

  const type = successStatusCodes.has(status) ? "success" : "error"
  const title = data?.errors?.messages || type.toUpperCase()
  const description = data?.errors?.data?.join("\n")

  if (title || description) {
    notifyUser({ title, description, type })
  }
}
