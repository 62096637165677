import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "addPost",
  initialState: {
    showPanel: false,
    referencePost: null,
  },
  reducers: {
    toggleDialog: (state, action) => {
      state.showPanel = action.payload
    },
    setReference: (state, action) => {
      state.referencePost = action.payload
    },
  },
})

export const { toggleDialog, setReference } = slice.actions

export const addPostState = state => state?.addPost

export default slice.reducer
