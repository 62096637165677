import React from "react"

const Wrapped = WrappedComponent => {
  // eslint-disable-next-line react/display-name
  return ({ refs = null, ...props }) => {
    const { labelconfig } = props

    let ref = ""
    if (refs) {
      ref = React.createRef()
    }

    return (
      <>
        {labelconfig?.label !== undefined && (
          <div className="f-14 pb-1">
            {labelconfig?.label && labelconfig?.label}
            {labelconfig?.label && labelconfig?.required && (
              <span className="align-bottom"> *</span>
            )}
          </div>
        )}

        <WrappedComponent ref={ref || undefined} {...props} />

        {!labelconfig?.error && labelconfig?.description && (
          <div className="f-12 text-gray-500 px-1.5 mt-0.5">
            {labelconfig?.description}
          </div>
        )}
        {labelconfig?.error && (
          <div className="mt-1 ml-1 text-sm font-medium text-red-600">
            {labelconfig?.error}
          </div>
        )}
      </>
    )
  }
}

export default Wrapped
