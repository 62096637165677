import { Select } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"
import styled from "styled-components"

const CustomSelect = styled(Select)`
  width: 100%;
  min-height: 40px !important;

  > div {
    min-height: 40px !important;
  }
`

function CPSelect({ labelconfig = {}, ...props }) {
  return <CustomSelect rows={4} {...labelconfig} {...props} />
}

export default CPInputWithLabelHOC(CPSelect)
