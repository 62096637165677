import { Badge, Dropdown, Tooltip } from "antd"
import CPButton from "components/CP/CPButton"
import ProfileMenu from "components/Header/ProfileMenu"
import NotificationDropdown from "components/Notifications/NotificationContainer"
import Link from "next/link"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { messageState, setListExpand } from "states/slices/messageSlice"
import { profileState } from "states/slices/profileReducer"

export default function UserBox({ onClose, setAddCommunity, setAddCompany }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const { userInfo } = useSelector(profileState)
  const { isListExpand } = useSelector(messageState)

  if (!userInfo) {
    return (
      <>
        <Link
          passHref
          prefetch={false}
          href="/login"
          className="mr-4 text-black hover:text-primary"
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          Login
        </Link>
        <Link
          passHref
          prefetch={false}
          href="/signup"
          className="text-black cursor-pointer"
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          <CPButton type="primary" className="px-5">
            <span>Sign up</span>
          </CPButton>
        </Link>
      </>
    )
  }

  return (
    <div id="dropdown-container" className="flex flex-row-reverse items-center">
      <Dropdown
        menu={{
          items: [
            {
              style: { padding: 0 },
              key: "1",
              label: (
                <ProfileMenu
                  className="rounded-lg"
                  setAddCommunity={setAddCommunity}
                  setAddCompany={setAddCompany}
                />
              ),
            },
          ],
        }}
        arrow
        trigger={["click"]}
        placement="bottomRight"
        className="hidden sm:block p-0"
        destroyPopupOnHide
        getPopupContainer={() => document.getElementById("dropdown-container")}
      >
        <div
          className="w-8 h-8 rounded-full border border-solid border-black bg-cover cursor-pointer bg-center ml-2"
          style={{
            backgroundImage: `url(${
              userInfo?.icon || "/assets/onBoarding/profile_avatar.jpg"
            })`,
          }}
        />
      </Dropdown>
      <Link
        passHref
        prefetch={false}
        href={`/profile/${userInfo?.username}`}
        className="inline-block sm:hidden"
        onClick={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <div
          className="w-8 h-8 rounded-full border border-solid border-black bg-cover cursor-pointer bg-center ml-2"
          style={{
            backgroundImage: `url(${
              userInfo?.icon || "/assets/onBoarding/profile_avatar.jpg"
            })`,
          }}
        />
      </Link>

      <NotificationDropdown />

      <div
        className="text-black mx-2 cursor-pointer"
        style={{ height: 19 }}
        onClick={() => {
          dispatch(setListExpand(!isListExpand))
          if (onClose) {
            onClose()
          }
        }}
      >
        <Tooltip placement="top" title="Messages">
          <Badge
            count={userInfo?.message_badge}
            overflowCount={10}
            size="small"
          >
            <span className="icon-ic_drafts_24px f-19 block hover:text-primary" />
          </Badge>
        </Tooltip>
      </div>
      <Tooltip placement="top" title="Bookmarks">
        <Link
          passHref
          prefetch={false}
          href={`/profile/${userInfo?.username}?tab=bookmarks`}
          className="text-black hover:text-primary mx-2 inline-block f-19"
          style={{ height: 22 }}
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          <span className="icon-ic_bookmark_24px" />
        </Link>
      </Tooltip>
      <Tooltip placement="top" title="My Feed">
        <Link
          passHref
          prefetch={false}
          href="/"
          className={`${
            String(router.pathname)?.includes("feed")
              ? "text-primary"
              : "text-black hover:text-primary"
          } mx-2 cursor-pointer inline-block f-19`}
          style={{ height: 24 }}
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          <span className="icon-ic_home_24px" />
        </Link>
      </Tooltip>
    </div>
  )
}
