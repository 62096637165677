import { Badge, Tabs } from "antd"
import NotificationMessages from "components/Notifications/NotificationMessages"

import NotificationRequests from "components/Notifications/NotificationRequests"

export default function NotificationContent({ item, drawer = false }) {
  return (
    <div style={{ width: drawer ? "" : 300 }}>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: (
              <Badge
                count={item?.notification_badge}
                offset={[5, 0]}
                dot
                size="small"
                className="hover:text-primary"
              >
                Notifications
              </Badge>
            ),
            children: (
              <NotificationMessages
                style={{ height: drawer ? "" : 265 }}
                className="overflow-auto"
              />
            ),
          },
          {
            key: "2",
            label: (
              <Badge
                count={item?.connection_request_badge}
                offset={[5, 0]}
                dot
                size="small"
                className="hover:text-primary"
              >
                Requests
              </Badge>
            ),
            children: (
              <NotificationRequests
                style={{ height: drawer ? "" : 265 }}
                className="overflow-auto"
              />
            ),
          },
        ]}
      />
    </div>
  )
}
