import { Upload } from "antd"
import CPButton from "components/CP/CPButton"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"

function CPUpload(props) {
  return (
    <Upload {...props?.uploaderProps}>
      <CPButton
        block
        className="f-13 px-6"
        style={{ height: 40 }}
        icon={<span className="icon-ic_file_upload_24px mr-2" />}
      >
        <span>Select File</span>
      </CPButton>
    </Upload>
  )
}

export default CPInputWithLabelHOC(CPUpload)
