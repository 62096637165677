import CategoryDropDownField from "components/CustomFields/CategoryDropDownField"
import CountryAndCitySelector from "components/CustomFields/CountryAndCitySelector"
import FormGenerator from "components/FormGenerator"

const formRules = {
  name: {
    required: "community name is required",
  },
}

const formFields = [
  {
    key: "name",
    title: "Community Name",
    fieldType: "input",
    col: {
      xs: 24,
    },
    placeholder: false,
  },
  {
    key: "community_category_id",
    title: "Community Category",
    fieldType: "customSingleField",
    col: {
      xs: 24,
    },
    render: formProps => {
      const { register, setValue, watch, errors } = formProps
      register("community_category_id")

      const value = watch("community_category_id")

      return (
        <>
          <CategoryDropDownField
            allowClear
            showSearch
            placeholder="Ex: branding"
            labelconfig={{
              label: "Community Category",
              error: errors?.community_category_id?.message || null,
              required: formRules?.community_category_id?.required,
            }}
            onChange={val => setValue("community_category_id", val || null)}
            defaultValue={value}
          />
          <div className="f-12 text-gray-500 p-1">
            Choose the category of your community to help your audience find you
            better!
          </div>
        </>
      )
    },
  },
  {
    key: "city_id",
    title: "Location",
    fieldType: "customSingleField",
    col: {
      xs: 24,
    },
    placeholder: false,
    render: formProps => {
      const { register, setValue, watch } = formProps
      register("city_id")
      register("country_id")

      const country = watch("country_id")
      const city = watch("city_id")

      return (
        <CountryAndCitySelector
          initialData={{
            country_id: country || null,
            city_id: city || null,
          }}
          onChange={data => {
            setValue("city_id", data?.city_id || null)
            setValue("country_id", data?.country_id || null)
          }}
        />
      )
    },
  },
]

export default function AddCommunityInfo({
  loading,
  onSubmit,
  extraButtons,
  initialData,
  submitText = "Next",
}) {
  return (
    <div className="p-4">
      <FormGenerator
        loading={loading}
        rules={formRules}
        fields={formFields}
        onSubmit={onSubmit}
        extraButtons={extraButtons}
        submitText={submitText}
        minHeight={250}
        initialData={{ ...initialData }}
      />
    </div>
  )
}
