import { Avatar } from "antd"
import moment from "moment"
import Link from "next/link"

export default function NotificationMessageItem({ item }) {
  return (
    <div className="mb-2 items-center flex">
      <Link passHref prefetch={false} href={item?.link || ""}>
        <span style={{ width: 32 }} className="cursor-pointer">
          <Avatar
            size={32}
            src={item?.icon || "/assets/onBoarding/profile_avatar.jpg"}
            alt={item?.title || ""}
            className="bg-gray-100"
          />
        </span>
      </Link>
      <Link passHref prefetch={false} href={item?.link || ""}>
        <span className="ml-3 text-black cursor-pointer f-12 flex flex-col">
          <span className="f-12">{item?.description}</span>
          {item?.created_at && (
            <span className="f-10 text-gray-500 font-normal">
              {moment(item?.created_at).fromNow()}
            </span>
          )}
        </span>
      </Link>
    </div>
  )
}
