import { InputNumber } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"

function CPInput(props) {
  const className = `${
    props?.className ? props?.className + " " : ""
  }w-full py-1 border rounded-lg border-gray-300`

  return <InputNumber rows={4} {...props} className={className} />
}

export default CPInputWithLabelHOC(CPInput)
