import { useState } from "react"

export const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleModalOpening = () => {
    setModalIsOpen(true)
  }

  const handleModalClosing = () => {
    setModalIsOpen(false)
  }

  return {
    selectors: {
      modalIsOpen,
    },
    actions: {
      handleModalOpening,
      handleModalClosing,
    },
  }
}
