export const truncate = (
  value,
  { length = 100, ending = "...", disabled = false, word = true } = {}
) => {
  if (disabled || !value) {
    return value
  }

  if (value.length > length) {
    const trimmedString = value.substr(0, length)

    if (word) {
      return (
        trimmedString.substr(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        ) + ending
      )
    }
    return trimmedString + ending
  }
  return value
}

export const replaceLinksInText = text => {
  if (!text) {
    return text
  }

  const linkRegex =
    /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi
  const htmlPattern =
    /<a\s+href\s*=\s*(?:"([^"]*)"|'([^']*)'|([^>\s]+)|([^>]+))[^>]*>([^<]*)<\/a>/g

  return text
    .replace(htmlPattern, url =>
      url.replace(
        "<a",
        '<a class="cursor-pointer f-14 text-blue-500 hover:text-blue-700"'
      )
    )
    .replace(
      linkRegex,
      url =>
        `<a href="${url}" target="_blank" class="cursor-pointer f-14 text-blue-500 hover:text-blue-700" rel="noopener noreferrer">${url}</a>`
    )
}

export const isEmpty = obj => {
  const hasOwnProperty = Object.prototype.hasOwnProperty
  if (obj == null) {
    return true
  }
  if (obj.length > 0) {
    return false
  }
  if (obj.length === 0) {
    return true
  }

  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

export const guid = () => {
  function idGenerator() {
    return Math.floor((1 + Math.random()) * 0x10000).toString()
  }

  return `${idGenerator()}-${idGenerator()}-${idGenerator()}`
}

export const shapeImages = (index, fileLength = 0) => {
  if (!fileLength) {
    return 0
  }

  switch (fileLength) {
    case 1:
      return index === 0 ? 24 : 8
    case 2:
      return 12
    case 3:
      return index === 0 ? 24 : 12
    case 4:
      return 12
    case 5:
      return index < 2 ? 12 : 8
    case 6:
      return index === 0 ? 24 : index < 3 ? 12 : 8
    case 7:
      return index < 4 ? 12 : 8
    case 8:
      return index < 2 ? 12 : 8
    case 9:
      return index === 0 ? 24 : index < 3 ? 12 : 8
    default:
      return index === 0 ? 24 : 8
  }
}

export const buildSearchQuery = searchFilters => {
  if (!searchFilters) {
    return ""
  }

  const filters = new URLSearchParams()
  Object.entries(searchFilters)?.forEach(([key, value]) => {
    if (value) {
      filters.append(key, String(value))
    }
  })

  const result = filters.toString()
  return result ? `&${result}` : ""
}
