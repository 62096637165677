import Cookies from "js-cookie"
import apiService from "services/apiService"
import { getItem } from "utils/storage"

export async function logoutOperation() {
  try {
    await apiService.post("/api/auth/logout", { fcm_token: getItem("fcm") })
  } catch (error) {
    console.error("Error during logout:", error)
  }

  const cookiesToRemove = new Set([
    "fcm",
    "inv_id",
    "inv_type",
    "inv_token",
    "user_info",
    "access_token",
    "suspended_message",
  ])

  if (Cookies) {
    cookiesToRemove.forEach(cookie => Cookies.remove(cookie))
  }

  window.location.href = "/login"
}
