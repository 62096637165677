import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Card, Col, Input, Row } from "antd"
import CPButton from "components/CP/CPButton"
import Link from "next/link"
import { useState } from "react"
import { notifyUser } from "services/notifyToast"
import { setItem } from "utils/storage"

const Lock = ({ login }) => {
  const [key, setKey] = useState("")

  const handleUnlock = () => {
    if (key !== "lincoln") {
      return notifyUser({
        type: "error",
        description: `${key} is a wrong one!`,
      })
    }

    setItem("lock", "granted!")

    if (login) {
      login()
    }

    notifyUser({
      type: "success",
      description: "Welcome to our development!",
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    handleUnlock()
  }

  return (
    <Row align="middle" className="h-screen">
      <Col
        xs={{ span: 22, offset: 1 }}
        lg={{ span: 10, offset: 7 }}
        xl={{ span: 8, offset: 8 }}
        xxl={{ span: 6, offset: 9 }}
      >
        <Card
          className="text-center rounded-xl"
          style={{ boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.1)" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/assets/icons/android-chrome-512x512.png"
                alt="logo"
                className="h-24 mb-5"
              />
              <h1 className="f-29 font-bold m-0">Unlock</h1>
              <p className="f-16 text-gray-500 mb-5">
                Enter the password to login
              </p>

              <Input.Password
                style={{ height: 60 }}
                placeholder=""
                value={key}
                type="password"
                className="f-25 text-center rounded-lg mb-5"
                onChange={event => setKey(event.target.value)}
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />

              <CPButton
                type="primary"
                style={{ height: "50px" }}
                htmlType="submit"
                className="m-0"
                block
              >
                Submit
              </CPButton>

              <div className="mt-5 text-gray-500">
                Back to{" "}
                <Link passHref prefetch={false} href="https://birdflocks.com/">
                  Birdflocks?
                </Link>
              </div>
            </div>
          </form>
        </Card>
      </Col>
    </Row>
  )
}

export default Lock
