import { createSlice } from "@reduxjs/toolkit"
import apiService from "services/apiService"

const slice = createSlice({
  name: "projectStatus",
  initialState: {
    loading: false,
    data: null,
    error: false,
    errorMessage: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setError: (state, action) => {
      state.errorMessage = action.payload
    },
  },
})

const { setData, setLoading, setError } = slice.actions

export const projectStatusReduxState = state => state.projectStatus
export const getProjectStatusList = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const res = await apiService.get("/api/list/get-project-statuses")
    const dataList = res?.data?.data

    if (res.status === 200 && dataList?.length) {
      dispatch(setData(dataList))
    }
  } catch (e) {
    dispatch(setError(e?.response?.data))
  }

  dispatch(setLoading(false))
}

export default slice.reducer
