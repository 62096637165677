import AddCommunityInfo from "components/Community/addCommunity/AddCommunityInfo"
import AddCommunityPolicy from "components/Community/addCommunity/AddCommunityPolicy"
import AddCommunityPrivacyLevel from "components/Community/addCommunity/AddCommunityPrivacyLevel"
import { CPModal } from "components/CP/CPModal"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

export default function ModalAddCompany({ showModal, toggleModal }) {
  const router = useRouter()
  const { checkSubscription } = useSubscriptionContext()

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [stepOne, setStepOne] = useState(true)
  const [stepTwo, setStepTwo] = useState(false)
  const [stepThree, setStepThree] = useState(false)

  useEffect(() => {
    if (showModal) {
      return
    }

    setStepOne(true)
    setStepTwo(false)
    setLoading(false)
    setStepThree(false)
    setIsSubmitting(false)

    setData({})
  }, [showModal])

  useEffect(() => {
    if (isSubmitting) {
      handleSubmit()
    }
  }, [isSubmitting])

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const res = await apiService.post("/api/community", data)
      if (res.status === 200) {
        setTimeout(() => {
          toggleModal(false)
          return router.push(
            `/community/${res?.data?.data?.id}?type=show_invite`
          )
        }, 2000)
      }
    } catch (e) {
      checkSubscription(e)
      setLoading(false)
      setIsSubmitting(false)
    }
  }

  const collectData = (event, finalStep = false) => {
    setData(prev => ({ ...prev, ...event }))
    if (finalStep) {
      setIsSubmitting(true)
    }
  }

  const findTitle = () => {
    if (stepOne) {
      return "Build Your Community"
    }
    if (stepTwo) {
      return "Community Access Level"
    }
    if (stepThree) {
      return "Community Policy"
    }
  }

  return (
    <CPModal
      className="rounded-lg p-0"
      title={findTitle()}
      footer={null}
      width={500}
      centered
      destroyOnClose
      open={showModal}
      onCancel={() => toggleModal(false)}
    >
      <div className="absolute flex items-center left-0 bottom-0 mx-3 mb-6">
        <div
          className="rounded-full border border-primary ml-1 flex justify-center items-center"
          style={{ width: 16, height: 16 }}
        >
          {(stepOne || stepTwo || stepThree) && (
            <div
              className="bg-primary rounded-full"
              style={{ width: 8, height: 8 }}
            />
          )}
        </div>
        <div
          className="rounded-full border border-primary ml-1 flex justify-center items-center"
          style={{ width: 16, height: 16 }}
        >
          {(stepTwo || stepThree) && (
            <div
              className="bg-primary rounded-full"
              style={{ width: 8, height: 8 }}
            />
          )}
        </div>
        <div
          className="rounded-full border border-primary ml-1 flex justify-center items-center"
          style={{ width: 16, height: 16 }}
        >
          {stepThree && (
            <div
              className="bg-primary rounded-full"
              style={{ width: 8, height: 8 }}
            />
          )}
        </div>
      </div>

      {stepOne && (
        <AddCommunityInfo
          initialData={data}
          onSubmit={event => {
            collectData(event)
            setStepOne(false)
            setStepTwo(true)
          }}
        />
      )}
      {stepTwo && (
        <AddCommunityPrivacyLevel
          initialData={data}
          onBack={() => {
            setStepOne(true)
            setStepTwo(false)
          }}
          onSubmit={event => {
            collectData(event)
            setStepTwo(false)
            setStepThree(true)
          }}
        />
      )}
      {stepThree && (
        <AddCommunityPolicy
          initialData={data}
          loading={loading}
          onBack={event => {
            collectData(event)
            setStepTwo(true)
            setStepThree(false)
          }}
          onSubmit={event => collectData(event, true)}
        />
      )}
    </CPModal>
  )
}
