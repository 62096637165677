import { CheckOutlined } from "@ant-design/icons"
import LinkPreview from "components/Editor/LinkPreview"
import moment from "moment"

export default function ChatDetailsRow({ item } = {}) {
  return (
    <div className="flex items-center flex-shrink-0 my-4">
      <div
        className="w-8 h-8 rounded-full border border-solid border-gray-600 bg-cover mr-3.5 flex-shrink-0"
        style={{
          backgroundImage: `url(${
            item?.sender_icon || "/assets/onBoarding/profile_avatar.jpg"
          })`,
        }}
      />
      <div className="w-52">
        <div className="text-xs font-bold truncate">
          <span>{item?.sender_full_name}</span>
          <span className="f-10 text-gray-500 font-normal ml-3 inline-flex items-center">
            {moment(item?.created_at)
              .utc(moment().utcOffset())
              .toISOString()
              .substring(11, 16)}
          </span>
          <CheckOutlined className="ml-3 text-green-600" />
          {item?.seen_at && (
            <CheckOutlined
              style={{ marginLeft: -8 }}
              className="text-green-600"
            />
          )}
        </div>
        <div className="text-xs text-gray-500 truncate break-words whitespace-pre-line">
          {item?.message}

          <div>
            <LinkPreview body={item?.message} />
          </div>
        </div>
      </div>
    </div>
  )
}
