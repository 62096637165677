import { Badge, Button, Drawer, Popover, Tooltip } from "antd"
import NotificationContent from "components/Notifications/NotificationContent"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { profileState } from "states/slices/profileReducer"

export default function NotificationDropdown() {
  const { userInfo } = useSelector(profileState)
  const [instance, setInstance] = useState(null)
  const [counter, setCounter] = useState(0)
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const resetBadges = () => {
    setTimeout(() => {
      setInstance({
        ...instance,
        notification_badge: 0,
        connection_request_badge: 0,
      })
    }, 5000)
  }

  useEffect(() => {
    if (!instance) {
      return
    }
    setCounter(
      (instance?.notification_badge || 0) +
        (instance?.connection_request_badge || 0)
    )
  }, [instance, userInfo])

  useEffect(() => {
    if (!userInfo) {
      return
    }
    setInstance(userInfo)
  }, [userInfo])

  return (
    <>
      <Badge
        count={counter}
        overflowCount={10}
        size="small"
        className="block sm:hidden mx-2"
        onClick={resetBadges}
      >
        <Button
          type="ghost"
          className="p-0 m-0 transition-all"
          style={{ minHeight: "22px", height: "22px" }}
          onClick={() => setVisibleDrawer(true)}
        >
          <span className="icon-ic_notifications_24px hover:text-primary f-20" />
        </Button>
      </Badge>
      <Drawer
        closeIcon=""
        placement="right"
        open={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        width="80%"
        styles={{ body: { padding: "8px 15px" }, header: { padding: 0 } }}
      >
        <NotificationContent className="w-full" item={instance} drawer />
      </Drawer>

      <Popover
        arrow={{ pointAtCenter: true }}
        placement="bottomRight"
        content={<NotificationContent item={instance} />}
        trigger="click"
        destroyTooltipOnHide
        className="hidden sm:block"
      >
        <Tooltip placement="bottom" title="Notification">
          <div
            className="text-black hover:text-primary mx-2 cursor-pointer"
            style={{ height: 19, transition: "color ease-in-out 0.4s" }}
          >
            <Badge
              count={counter}
              overflowCount={10}
              size="small"
              onClick={resetBadges}
            >
              <span className="icon-ic_notifications_24px hover:text-primary f-19" />
            </Badge>
          </div>
        </Tooltip>
      </Popover>
    </>
  )
}
