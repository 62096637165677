import Cookies from "js-cookie"

export function setItem(key, value) {
  if (!Cookies) {
    return null
  }

  const serializedValue =
    value.constructor === Object || value.constructor === Array
      ? JSON.stringify(value)
      : value
  Cookies.set(key, serializedValue, { expires: 60, secure: true })
}

export function getItem(key) {
  if (!Cookies) {
    return null
  }
  return Cookies?.get(key)
}

export function removeItem(key) {
  if (!Cookies) {
    return null
  }
  return Cookies.remove(key)
}
