import axios from "axios"
import { getItem } from "utils/storage"

const createApiService = () => {
  const instance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })

  instance.interceptors.request.use(
    async request => {
      const token = await getItem("access_token")
      if (token) {
        request.headers.Authorization = `Bearer ${token}`
      }
      return request
    },
    error => Promise.reject(error)
  )

  instance.interceptors.response.use(
    response => response,
    error => {
      console.error("Error in API response:", error)
      return Promise.reject(error)
    }
  )

  return instance
}

const apiService = createApiService()

export default apiService
