import Layout from "components/Layout"
import { SubscriptionProvider } from "components/Subscription/SubscriptionProvider"
import { DefaultSeo } from "next-seo"
import { ThemeProvider } from "next-themes"
import Head from "next/head"
import { Provider } from "react-redux"
import { makeStore } from "states/store"

import "tailwindcss/tailwind.css"
import "styles/globals.css"
import "styles/app.scss"

export default function App({ Component, pageProps }) {
  const initialState = pageProps.initialState

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <DefaultSeo
        twitter={{
          handle: "@birdflocks",
          site: "@birdflocks",
          cardType: "summary",
          image: "https://birdflocks.com/assets/onBoarding/cover.png",
        }}
        openGraph={{
          type: "website",
          site_name: "Birdflocks | The first AEC online community platform",
          images: [
            {
              url: "https://birdflocks.com/assets/onBoarding/cover.png",
            },
          ],
        }}
        additionalMetaTags={[
          { name: "application-name", content: "Birdflocks" },
          { name: "apple-mobile-web-app-capable", content: "yes" },
          { name: "apple-mobile-web-app-title", content: "Birdflocks" },
          { name: "apple-mobile-web-app-status-bar-style", content: "default" },
          { name: "mobile-web-app-capable", content: "yes" },
          { name: "format-detection", content: "telephone=no" },
          { name: "msapplication-tap-highlight", content: "no" },
          { name: "msapplication-TileColor", content: "#000000" },
          { name: "msapplication-config", content: "/browserconfig.xml" },
          { name: "theme-color", content: "#000000" },
        ]}
        additionalLinkTags={[
          { rel: "shortcut icon", href: "/favicon.ico" },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
          },
          {
            rel: "mask-icon",
            href: "/assets/icons/safari-pinned-tab.svg",
            color: "#fff",
          },
          {
            rel: "apple-touch-icon",
            href: "/assets/icons/apple-touch-icon.png",
          },
          ...Array.from({ length: 8 }, (_, size) => ({
            rel: "apple-touch-icon",
            href: `/assets/icons/apple-touch-icon-${57 + size * 15}x${
              57 + size * 15
            }.png`,
            sizes: `${57 + size * 15}x${57 + size * 15}`,
          })),
        ]}
      />

      <Provider store={makeStore(initialState)}>
        <ThemeProvider>
          <SubscriptionProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </SubscriptionProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}
