import { Avatar, Button } from "antd"
import moment from "moment"
import { useState } from "react"
import apiService from "services/apiService"

export default function RequestConnectItem({ item }) {
  const [loading, setLoading] = useState(false)

  const Message = () => {
    switch (item.status) {
      case "accepted":
        return "Request Accepted"
      case "rejected":
        return "Request Removed"
      default:
        return item?.mutual_friends + " mutual friends"
    }
  }

  const sendData = type => {
    setLoading(true)

    const body = {
      status: type,
    }
    apiService
      .put(`/api/network/connection/${item.id}`, body)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const connectionHandler = () => {
    setLoading(true)
    sendData("accepted")
    item.status = "accepted"
  }

  const deleteHandler = () => {
    setLoading(true)
    sendData("rejected")
    item.status = "rejected"
  }

  return (
    <div className="mb-2 flex">
      <Avatar
        size={32}
        style={{ minWidth: 32 }}
        src={item.profile_photo || "/assets/onBoarding/profile_avatar.jpg"}
        alt={item.title || ""}
        className="bg-gray-100"
      />
      <div className="flex flex-col ml-3 flex-grow">
        <span className="f-12">
          {item.first_name} {item.last_name}
        </span>
        <span className="f-10 text-gray-500">
          {Message(item)}
          {item?.created_at && (
            <span className="ml-2">{moment(item?.created_at).fromNow()}</span>
          )}
        </span>

        {item.status === "pending" && (
          <div className="flex mt-2 w-full">
            <Button
              type="primary"
              disabled={loading}
              className="px-5"
              onClick={connectionHandler}
            >
              Confirm
            </Button>
            <Button
              type="light"
              className="px-5"
              disabled={loading}
              onClick={deleteHandler}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
