import { Layout } from "antd"
import Footer from "components/Footer"
import Header from "components/Header/Header"
import LoadCookiePermission from "components/LoadCookiePermission"
import Lock from "components/Lock"
import Messaging from "components/Messaging"
import FirebaseApp from "components/WebPush"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { profileState } from "states/slices/profileReducer"
import { getItem } from "utils/storage"

const footerConditions = [
  "details",
  "companies",
  "individuals",
  "community",
  "communities",
  "privacy",
  "cookies",
]

export default function Index({ children }) {
  const router = useRouter()
  const [hideFooter, toggleFooter] = useState(false)
  const [showContent, toggleContent] = useState(true)

  const { userInfo } = useSelector(profileState)
  const isHome = router?.pathname === "/"

  const checkDomain = useMemo(() => {
    const origin =
      typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : ""
    return !(
      (origin.includes("test") || origin.includes("beta")) &&
      !getItem("lock")
    )
  }, [])

  const shouldToggleFooter = useMemo(
    () => footerConditions?.some(item => router?.pathname?.includes(item)),
    [router]
  )

  useEffect(() => {
    toggleFooter(shouldToggleFooter)
  }, [shouldToggleFooter])

  useEffect(() => {
    toggleContent(checkDomain)
  }, [checkDomain])

  if (!showContent) {
    return (
      <Layout style={{ background: "#f2f2f2" }}>
        <Lock login={() => toggleContent(true)} />
      </Layout>
    )
  }

  return (
    <>
      <Header isHome={!userInfo && isHome} />

      <Layout style={{ paddingTop: 56, backgroundColor: "#f2f2f2" }}>
        {children}
      </Layout>

      <LoadCookiePermission />

      {userInfo && (
        <>
          <Messaging />
          <FirebaseApp />
        </>
      )}
      {!isHome && !hideFooter && <Footer />}
    </>
  )
}
