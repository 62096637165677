import Script from "next/script"

export default function LoadCookiePermission() {
  return (
    <Script strategy="lazyOnload" id="cookie" type="text/javascript">
      {`
        var _iub = _iub || [];
        _iub.csConfiguration = {
          "lang": "en",
          "perPurposeConsent": true,
          "siteId": 2131350,
          "whitelabel": false,
          "cookiePolicyId": 82532799,
          "banner": {
            "acceptButtonCaptionColor": "white",
            "acceptButtonColor": "#fbb62d",
            "acceptButtonDisplay": true,
            "backgroundColor": "#ffffff",
            "closeButtonRejects": true,
            "customizeButtonCaptionColor": "white",
            "customizeButtonColor": "#212121",
            "customizeButtonDisplay": true,
            "fontSizeBody": 16,
            "fontSizeCloseButton": 16,
            "position": "bottom",
            "textColor": "#010101"
          }
        };
      `}
      {`!function (w, d) {
        var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "//cdn.iubenda.com/cs/iubenda_cs.js"; tag.parentNode.insertBefore(s, tag); };
        if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; }
      }(window, document);`}
    </Script>
  )
}
