import { createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"
import apiService from "services/apiService"
import { getItem, setItem } from "utils/storage"

const slice = createSlice({
  name: "userInfo",
  initialState: {
    loading: true,
    userInfo: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setProfile: (state, action) => {
      state.userInfo = action.payload
    },
  },
})

export const { setProfile, setLoading } = slice.actions

export const profileState = state => state?.userInfo

export const getServerProfile = token => async dispatch => {
  if (!token) {
    return
  }

  try {
    const res = await apiService.get("/api/profile/dashboard", {
      headers: { Authorization: `Bearer ${token}` },
    })
    dispatch(setProfile({ ...res?.data?.data }))
  } catch (e) {
    console.log(e)
  }
}

export const getProfile = () => async dispatch => {
  if (!getItem("access_token")) {
    dispatch(setProfile(null))
    dispatch(setLoading(false))

    return
  }

  try {
    dispatch(setLoading(true))
    const res = await apiService.get("/api/profile/dashboard")
    const user = { ...res?.data?.data }
    dispatch(setProfile(user))
    setItem("user_info", user)
    dispatch(setLoading(false))

    return user
  } catch (e) {
    console.log(e)

    if (
      e?.response?.status === 401 ||
      e?.response?.data?.errors?.messages === "Unauthenticated"
    ) {
      dispatch(setProfile(null))
      Cookies?.remove("access_token")
      Cookies?.remove("user_info")
    } else {
      const getUserInfo = getItem("user_info")
      dispatch(setProfile(getUserInfo))
    }

    dispatch(setLoading(false))
  }
}

export default slice.reducer
