import CPSelect from "components/CP/CPSelect"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  categoryReduxState,
  getCategoryList,
} from "states/slices/communityCategorySlice"

function CategoryDropDownField(props) {
  const dataInRedux = useSelector(categoryReduxState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!dataInRedux?.data) {
      dispatch(getCategoryList())
    }
  }, [])

  return (
    <CPSelect
      {...props}
      loading={dataInRedux?.loading}
      disabled={dataInRedux?.loading}
      options={dataInRedux?.data?.map(item => {
        return { label: item?.title, value: item?.id }
      })}
    />
  )
}

export default CategoryDropDownField
