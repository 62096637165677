import "firebase/messaging"
import firebase from "firebase/app"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import apiService from "services/apiService"
import { notifyUser } from "services/notifyToast"
import { setProfile } from "states/slices/profileReducer"
import { getItem, setItem } from "utils/storage"

const firebaseCloudMessaging = {
  init: async () => {
    try {
      await firebase.initializeApp({
        name: "birdflocks",
        projectId: "birdflocks",
        messagingSenderId: "640843262960",
        authDomain: "birdflocks.firebaseapp.com",
        storageBucket: "birdflocks.appspot.com",
        apiKey: "AIzaSyBSIj7r5BpPGGwvCyvqmS7pXjQhe_GRJ60",
        appId: "1:640843262960:web:290156deb077a489e58b35",
        measurementId: "G-F4B4QKLX3P",
      })
      return firebaseCloudMessaging.get()
    } catch (error) {
      console.error(error)
    }

    return null
  },
  get: async () => {
    try {
      const messaging = firebase?.messaging()
      const status = await Notification?.requestPermission()
      if (status && status === "granted") {
        const fcm_token = await messaging.getToken()
        if (fcm_token) {
          return fcm_token
        }
      }
    } catch (error) {
      return firebaseCloudMessaging.init()
    }

    return null
  },
}

export default function FirebaseApp() {
  const [loading, setLoading] = useState(false)
  const [sendToServer, setSendServer] = useState(false)
  const getUserInfo = getItem("user_info")
  const dispatch = useDispatch()

  const getMessage = () => {
    const messaging = firebase.messaging()
    messaging.onMessage(message => {
      if (!message?.data?.msg) {
        return
      }

      const userInfo = JSON.parse(getUserInfo)
      const prevUserInfo = {
        ...userInfo,
        notification_badge: (userInfo.notification_badge || 0) + 1,
      }
      dispatch(setProfile(prevUserInfo))

      notifyUser({
        type: "warning",
        description: message?.data?.msg,
      })
    })
  }

  const registerToServer = async token => {
    try {
      setSendServer(true)
      await apiService.post("/api/profile/set-fcm-token", { fcm_token: token })
      setItem("fcm", token)
    } catch (e) {
      console.log(e)
    }
  }

  const setToken = async () => {
    try {
      setLoading(true)
      const token = await firebaseCloudMessaging.get()
      if (token) {
        getMessage()
        if (!sendToServer) {
          registerToServer(token)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!getUserInfo || loading) {
      return
    }
    setToken()
  }, [getUserInfo])

  return null
}
