import { useEffect, useState } from "react"
import apiService from "services/apiService"
import { truncate } from "utils/global"

export default function LinkPreview({ body, className, closable }) {
  const [link, setLink] = useState("")
  const [prev, setPrev] = useState("")
  const [data, setData] = useState(null)
  const [cleanUp, setCleanUp] = useState(false)

  body = String(body || "").replace(
    // eslint-disable-next-line
    /src="(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)"/,
    ""
  )

  const getLink = async link => {
    const split = link.split("\n")

    try {
      setLink(split[0])
      setPrev(split[0])

      const res = await apiService.get(`/api/network/feed/og?url=${split[0]}`)
      if (Array.isArray(res.data.data)) {
        return
      }

      setData(res.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (cleanUp || !body) {
      return
    }
    if (String(body)?.search("<iframe") >= 0) {
      return
    }

    const urlRegex =
      // eslint-disable-next-line
      /(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    const url = String(body)?.match(urlRegex)

    if (!url) {
      setData(null)
    }
    if (data && closable) {
      return
    }

    if (url && url.length && prev !== url[0]) {
      getLink(String(url[0]).trim())
    }

    return () => setCleanUp(true)
  }, [body, cleanUp, closable, data, prev])

  if (!data) {
    return <div />
  }

  const image =
    data["og:image"] || data["twitter:image"] || data["twitter:image:src"]
  const title = data["og:title"] || data["twitter:title"] || data.title
  const description =
    data["og:description"] || data["twitter:description"] || data.description

  return (
    data && (
      <div
        className={`${
          className ? className : "border border-gray-300"
        } overflow-hidden mb-3 rounded-lg`}
      >
        {image && (
          <a
            href={link || ""}
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <img src={image} alt="" className="w-full" />
          </a>
        )}

        {(title || description) && (
          <a
            href={link || ""}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500"
          >
            <div className="p-3 bg-gray-100 block">
              {title && (
                <h2 className="f-16 break-words block font-bold mb-1">
                  {truncate(title, { length: 90 })}
                </h2>
              )}
              {description && (
                <h3 className="f-13 break-words text-gray-500 block m-0">
                  {truncate(description, { length: 250 })}
                </h3>
              )}
            </div>
          </a>
        )}
      </div>
    )
  )
}
