import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "company",
  initialState: {
    loading: false,
    userPolicies: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUserPolicies: (state, action) => {
      state.userPolicies = action.payload
    },
  },
})

export const { setLoading, setUserPolicies } = slice.actions

export const companyReduxState = state => state.company

export default slice.reducer
