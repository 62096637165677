import { DatePicker } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"
import moment from "moment"
import momentGenerateConfig from "rc-picker/lib/generate/moment"

function CPDatePicker(props) {
  const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig)

  return (
    <MomentDatePicker
      {...props}
      value={props?.value ? moment(props?.value) : null}
      defaultValue={props?.defaultValue ? moment(props?.value) : null}
      className="w-full rounded-lg py-2"
    />
  )
}

export default CPInputWithLabelHOC(CPDatePicker)
