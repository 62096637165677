import { Modal } from "antd"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useModal } from "utils/hooks/useModal"

export const CPModal = ({ children, ...props }) => {
  const router = useRouter()
  const {
    selectors: { modalIsOpen },
    actions: { handleModalOpening, handleModalClosing },
  } = useModal()

  useEffect(() => {
    return () => {
      if (modalIsOpen) {
        if (props?.onCancel) {
          props.onCancel()
        }

        handleModalClosing()
      }
    }
  }, [router])

  useEffect(() => {
    if (props?.open) {
      handleModalOpening()
    } else {
      handleModalClosing()
    }
  }, [props?.open])

  return (
    <Modal
      open={modalIsOpen}
      keyboard={props.maskClosable !== false}
      {...props}
    >
      {children}
    </Modal>
  )
}
