import { Button, Col, Divider, Row, Upload } from "antd"
import ImgCrop from "antd-img-crop"
import Checkbox from "antd/lib/checkbox/Checkbox"
import CPButton from "components/CP/CPButton"
import CPDatePicker from "components/CP/CPDatePicker"
import CPInput from "components/CP/CPInput"
import CPInputNumber from "components/CP/CPInputNumber"
import CPSelect from "components/CP/CPSelect"
import CPTextArea from "components/CP/CPTextArea"
import CPUpload from "components/CP/CPUpload"
import { Controller, useForm } from "react-hook-form"

export default function FormGenerator(props) {
  const {
    fields,
    loading,
    onSubmit,
    initialData = null,
    rules: formRules,
    submitText,
    extraButtons,
    minHeight,
    maxLength,
  } = props
  const formProps = useForm({
    defaultValues: { ...initialData },
  })

  const {
    watch,
    control,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formProps

  const RenderInput = field => {
    return (
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CPInput
            name={field?.key}
            maxLength={maxLength}
            placeholder={
              field?.placeholder === true
                ? field?.title
                : field?.placeholder || ""
            }
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
        name={field?.key}
        rules={formRules?.[field?.key]}
        defaultValue={field.defaultValue}
      />
    )
  }

  const RenderInputNumber = field => {
    return (
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CPInputNumber
            name={field?.key}
            placeholder={
              field?.placeholder === true
                ? field?.title
                : field?.placeholder || ""
            }
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
        name={field?.key}
        rules={formRules?.[field?.key]}
      />
    )
  }

  const RenderTextArea = field => {
    return (
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CPTextArea
            name={field?.key}
            placeholder={
              field?.placeholder === true
                ? field?.title
                : field?.placeholder || ""
            }
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            limit={field?.limit}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
        name={field?.key}
        rules={formRules?.[field?.key]}
      />
    )
  }

  const RenderSelect = field => {
    return (
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <CPSelect
            name={field?.key}
            placeholder={
              field?.placeholder === true
                ? field?.title
                : field?.placeholder || ""
            }
            options={field?.options}
            loading={field?.loading}
            disabled={field?.disabled}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            showSearch={field?.showSearch || false}
            optionFilterProp={field?.optionFilterProp || "label"}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
        name={field?.key}
        control={control}
        rules={formRules?.[field?.key]}
      />
    )
  }

  const RenderUpload = field => {
    return (
      <Controller
        name={field?.key}
        control={control}
        rules={formRules?.[field?.key]}
        render={({ field: { value } }) => (
          <CPUpload
            name={field?.key}
            placeholder={
              field?.placeholder === true ? field?.title : field?.placeholder
            }
            uploaderProps={{
              accept: field?.accept,
              beforeUpload: false,
              progress: {
                showInfo: false,
                strokeWidth: 0.01,
              },
              onChange: data => {
                formProps?.setValue(field?.key, data || null)
              },
            }}
            value={value}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
      />
    )
  }

  const RenderUploadImage = field => {
    const { Dragger } = Upload

    return (
      <Controller
        name={field?.key}
        control={control}
        rules={formRules?.[field?.key]}
        render={({ field: { value } }) => {
          const img =
            value && typeof value !== "string"
              ? URL.createObjectURL(value)
              : null
          const dragImageComponent = (
            <Dragger
              name={field?.key}
              className="bg-transparent"
              style={{
                padding: "5px 15px",
                border: "dashed 2px #c38600",
              }}
              beforeUpload={e => {
                formProps?.setValue(field?.key, e || null)
              }}
              showUploadList={false}
            >
              <div className="ant-upload-text">
                Drag images here or browse to upload *
              </div>
            </Dragger>
          )

          return (
            <>
              {!value ? (
                field.cropper ? (
                  <ImgCrop rotationSlider>{dragImageComponent}</ImgCrop>
                ) : (
                  dragImageComponent
                )
              ) : (
                <div
                  style={{
                    ...(field.imageSize || {}),
                    backgroundImage: `url(${
                      typeof value === "string" ? value : img
                    })`,
                    boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
                  }}
                  className="mb-5 mx-auto bg-no-repeat bg-cover bg-center"
                >
                  <CPButton
                    onClick={() => formProps?.setValue(field?.key, undefined)}
                    className=" rounded-full float-right mt-3 mr-3 w-8 h-8 px-0"
                  >
                    <span className="icon-ic_delete_24px" />
                  </CPButton>
                </div>
              )}
            </>
          )
        }}
      />
    )
  }

  const RenderDatePicker = field => {
    return (
      <Controller
        control={control}
        name={field?.key}
        rules={formRules?.[field?.key]}
        render={({ field: { value } }) => (
          <CPDatePicker
            name={field?.key}
            placeholder={
              field?.placeholder === true ? field?.title : field?.placeholder
            }
            value={value}
            onChange={(date, dateString) => {
              formProps?.setValue(field?.key, dateString)
            }}
            labelconfig={{
              label: field?.title,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          />
        )}
      />
    )
  }

  const RenderCheckBox = field => {
    return (
      <Controller
        control={control}
        name={field?.key}
        rules={formRules?.[field?.key]}
        render={({ field: { value } }) => (
          <Checkbox
            name={field?.key}
            placeholder={
              field?.placeholder === true ? field?.title : field?.placeholder
            }
            checked={!!(value === 1 || value === true)}
            onChange={date => {
              formProps?.setValue(
                field?.key,
                field?.numeric
                  ? date?.target?.checked
                    ? 1
                    : 0
                  : date?.target?.checked
              )
            }}
            labelconfig={{
              label: field?.title,
              disabled: field?.disabled,
              error: errors?.[field?.key]?.message || null,
              required: formRules?.[field?.key]?.required,
              description: field?.description,
            }}
          >
            <span className="ml-1">{field?.title}</span>
          </Checkbox>
        )}
      />
    )
  }

  const RenderCustomSingleField = field => {
    return (
      <Controller
        control={control}
        name={field?.key}
        rules={formRules?.[field?.key]}
        render={() => field?.render(formProps)}
      />
    )
  }

  const RenderCustom = field => {
    return field?.render(formProps)
  }

  function renderField(field) {
    if (field?.hideIf?.fields && field?.hideIf?.condition) {
      const targetFieldsVal = watch([...field.hideIf.fields])
      if (field?.hideIf?.condition(targetFieldsVal)) {
        return ""
      }
    }

    switch (field?.fieldType) {
      case "input":
        return RenderInput(field)
      case "inputNumber":
        return RenderInputNumber(field)
      case "select":
        return RenderSelect(field)
      case "textArea":
        return RenderTextArea(field)
      case "upload":
        return RenderUpload(field)
      case "uploadImage":
        return RenderUploadImage(field)
      case "datePicker":
        return RenderDatePicker(field)
      case "checkbox":
        return RenderCheckBox(field)
      case "customSingleField":
        return RenderCustomSingleField(field)
      case "custom":
        return RenderCustom(field)
      case "empty":
      default:
        return ""
    }
  }

  return (
    <form onSubmit={handleSubmit((v, e) => onSubmit(v, e, formProps))}>
      <Row gutter={[15, 15]} style={{ minHeight }}>
        {fields?.map((field, index) => (
          <Col key={index} {...field?.col}>
            {renderField(field)}
          </Col>
        ))}
      </Row>

      <Divider className="mb-4" />

      <div className="flex justify-end">
        {extraButtons?.length &&
          extraButtons.map((item, index) => (
            <>
              <Button
                key={index}
                disabled={loading || isSubmitting}
                type="light"
                htmlType="button"
                className="px-8"
                onClick={() => {
                  if (item?.onClick && typeof item?.onClick === "function") {
                    item?.onClick(getValues(), formProps)
                  }
                }}
              >
                {item?.label || "info"}
              </Button>
              {item?.space && <div className="flex-grow" />}
            </>
          ))}

        <CPButton
          type="primary"
          className="px-8"
          loading={loading || isSubmitting}
          htmlType="submit"
        >
          {submitText || "Submit"}
        </CPButton>
      </div>
    </form>
  )
}
